import React, {useCallback, useEffect, useState} from 'react';

import {useHotKey} from '@pexip/hooks';

import {useAssertedContext} from '../hooks/useAssertedContext';
import {
    useToggleAudioMuted,
    useToggleVideoMuted,
    useAudioMuteState,
    useVideoMuteState,
} from '../hooks/userMedia';
import {MeeContext, onPresentation} from '../contexts/mee.context';
import {Toolbar} from '../views/Toolbar/Toolbar.view';

const canShare =
    navigator?.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices;

export const Footer: React.FC<{
    leave: () => void;
}> = ({leave}) => {
    const mee = useAssertedContext(MeeContext);

    const [isPresenting, setPresenting] = useState(false);

    const audioMuted = useAudioMuteState();
    const videoMuted = useVideoMuteState();
    const toggleAudioMutedState = useToggleAudioMuted();
    const toggleVideoMutedState = useToggleVideoMuted();

    const togglePresenting = useCallback(() => {
        isPresenting ? void mee.stopPresenting() : void mee.present();
    }, [isPresenting, mee]);

    useHotKey({key: 'm', handler: toggleAudioMutedState});
    useHotKey({key: 'c', handler: toggleVideoMutedState});

    useEffect(() => onPresentation.add(stream => setPresenting(!!stream)), []);

    return (
        <Toolbar
            audioMuted={audioMuted ?? true}
            videoMuted={videoMuted ?? true}
            isPresenting={isPresenting}
            toggleAudioMutedState={toggleAudioMutedState}
            toggleVideoMutedState={toggleVideoMutedState}
            togglePresenting={togglePresenting}
            leave={leave}
            canShare={canShare}
        />
    );
};
