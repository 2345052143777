import React from 'react';

import {createMediaSignals, createMedia} from '@pexip/media';
import {isEmpty} from '@pexip/utils';
import {
    createPreviewAudioInputHook,
    createPreviewAudioOutputHook,
    createPreviewControllerHook,
    createPreviewVideoInputHook,
    UserMediaContext,
} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {toMediaDeviceInfo} from '@pexip/media-control';

import {config} from '../config';
import {DEFAULT_HEIGHT, DEFAULT_WIDTH} from '../constants';

export const mediaSignals = createMediaSignals([
    'onStatusChanged',
    'onDevicesChanged',
    'onStreamTrackEnabled',
    'onStreamTrackEnded',
    'onStreamTrackMuted',
    'onStreamTrackUnmuted',
    'onAudioMuteStateChanged',
    'onVideoMuteStateChanged',
    'onAddTrack',
    'onRemoveTrack',
    'onUpdatingMedia',
]);

export const mediaController = createMedia({
    getDefaultConstraints: () => ({
        audio: {
            ...(isEmpty(config.get('audioInput'))
                ? {}
                : {device: config.get('audioInput')}),
        },
        video: {
            ...(isEmpty(config.get('videoInput'))
                ? {}
                : {device: config.get('videoInput')}),
            width: DEFAULT_WIDTH,
            height: DEFAULT_HEIGHT,
        },
    }),
    getMuteState: () => ({
        audio: config.get('audioInputMuted'),
        video: config.get('videoInputMuted'),
    }),
    audioProcessors: [],
    videoProcessors: [],
    signals: mediaSignals,
});

export const usePreviewController = createPreviewControllerHook(() => ({
    getCurrentDevices: () => mediaController.devices,
    getCurrentMedia: () => mediaController.media,
    updateMainStream: mediaController.getUserMediaAsync,
    mediaSignal: mediaSignals.onMediaChanged,
    audioProcessors: [],
    videoProcessors: [],
}));

export const usePreviewAudioOutput = createPreviewAudioOutputHook(
    (value: MediaDeviceInfoLike) => config.set({key: 'audioOutput', value}),
);

export const usePreviewAudioInput = createPreviewAudioInputHook({
    get: () => config.get('audioInput'),
    getExpected: () => mediaController.media?.expectedAudioInput,
    set: (value?: MediaDeviceInfoLike) => {
        if (!value) {
            return;
        }
        config.set({
            key: 'audioInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        });
    },
});

export const usePreviewVideoInput = createPreviewVideoInputHook({
    get: () => config.get('videoInput'),
    getExpected: () => mediaController.media?.expectedVideoInput,
    set: (value?: MediaDeviceInfoLike) => {
        if (!value) {
            return;
        }
        config.set({
            key: 'videoInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        });
    },
});

export const MediaProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <UserMediaContext.Provider value={mediaController}>
        {children}
    </UserMediaContext.Provider>
);

export const muteAudio = (mute: boolean, persist?: boolean) =>
    config.set({key: 'audioInputMuted', value: mute, persist});
export const muteVideo = (mute: boolean, persist?: boolean) =>
    config.set({key: 'videoInputMuted', value: mute, persist});
