import React, {useEffect} from 'react';

import {FullscreenLayout, ThemeProvider} from '@pexip/components';
import {
    BlockedBrowserPermissionsInfoType,
    DevicesSelection,
    JoinMeetingButton,
    PreflightSelfview,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    getAudioOutput,
    onDeviceSelectChange,
    useUserMediaContext,
} from '@pexip/media-components';
import {noop} from '@pexip/utils';

import {
    useAudioInput,
    useAudioInputChangeHandler,
    useDevices,
    useHaveRequestedAudio,
    useHaveRequestedVideo,
    useMediaStatus,
    useMediaStream,
    useVideoInput,
    useVideoInputChangeHandler,
    useAudioOutputChangeHandler,
} from '../hooks/userMedia';
import {useConfig} from '../contexts/config.context';
import {NAMESPACE} from '../constants';
import {withHeader} from '../hocs/withHeader';
import {PreflightBox} from '../views/PreflightBox/PreflightBox.view';

import {SelfviewControls} from './SelfviewControls.viewModel';

export const Preflight: React.FC<{call: () => void}> = withHeader(({call}) => {
    const m = useUserMediaContext();

    const devices = useDevices();
    const streamStatus = useMediaStatus();

    const requestedAudio = useHaveRequestedAudio();
    const requestedVideo = useHaveRequestedVideo();

    const selectedAudioInput = useAudioInput();
    const selectedVideoInput = useVideoInput();
    const [savedAudioOutput] = useConfig('audioOutput');
    const [isVideoMediaMuted] = useConfig('videoInputMuted');
    const handleVideoInputChange = useVideoInputChangeHandler();
    const handleAudioInputChange = useAudioInputChangeHandler();
    const stream = useMediaStream();

    const selectedAudioOutput = getAudioOutput(devices, savedAudioOutput);
    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();
    useDeviceErrorMessage({
        setAudioInputError,
        setVideoInputError,
        streamStatus,
        requested: {audio: requestedAudio, video: requestedVideo},
    });

    const onVideoInputChange = onDeviceSelectChange(
        setVideoInputError,
        handleVideoInputChange,
    );

    const onAudioInputChange = onDeviceSelectChange(
        setAudioInputError,
        handleAudioInputChange,
    );

    const onAudioOutputChange = useAudioOutputChangeHandler(true);

    useEffect(() => {
        m.getUserMedia({audio: true, video: true});
    }, [m]);

    return (
        <FullscreenLayout>
            <ThemeProvider colorScheme="light">
                <PreflightBox>
                    <ThemeProvider colorScheme="dark">
                        <PreflightSelfview
                            showOverlayShadow
                            streamStatus={streamStatus}
                            username={NAMESPACE}
                            isVideoInputMuted={isVideoMediaMuted}
                            shouldShowBorder={false}
                            localMediaStream={stream}
                            preflightControls={
                                <SelfviewControls
                                    streamStatus={streamStatus}
                                    setShowHelpVideo={noop}
                                    permissionInfoType={
                                        BlockedBrowserPermissionsInfoType.Void
                                    }
                                />
                            }
                        />
                    </ThemeProvider>
                    <DevicesSelection
                        devices={devices}
                        audioInputError={audioInputError}
                        videoInputError={videoInputError}
                        disableAudioInputSelector={!requestedAudio}
                        disableVideoInputSelector={!requestedVideo}
                        disableAudioOutputSelector={false}
                        onVideoInputChange={onVideoInputChange}
                        onAudioInputChange={onAudioInputChange}
                        onAudioOutputChange={onAudioOutputChange}
                        requestedAudio={requestedAudio}
                        requestedVideo={requestedVideo}
                        videoInput={selectedVideoInput}
                        audioOutput={selectedAudioOutput}
                        audioInput={selectedAudioInput}
                        setShowHelpVideo={noop}
                    />
                    <JoinMeetingButton onClick={call} isDisabled={!stream} />
                </PreflightBox>
            </ThemeProvider>
        </FullscreenLayout>
    );
}, 'preflight');
