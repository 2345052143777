import React from 'react';

import {useMobileDevice} from '@pexip/components';
import {
    PreflightMediaControls,
    useAnalyzer,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useUserMediaContext,
} from '@pexip/media-components';
import type {UserMediaStatus} from '@pexip/media';
import {isInitialPermissionsNotGranted} from '@pexip/media';
import type {BlockedBrowserPermissionsInfoType} from '@pexip/media-components';

import {
    useToggleAudioMuted,
    useToggleVideoMuted,
    useAudioMuteState,
    useVideoMuteState,
    useInputDeviceStatusInfo,
    useVideoInputUnavailable,
    useAudioInputUnavailable,
    useHaveRequestedMedia,
    useSourceAudioTrack,
} from '../hooks/userMedia';

import {AudioMeter} from './AudioMeter.viewModel';

export type SelfviewControlType =
    | 'express'
    | 'step-by-step'
    | 'in-meeting'
    | 'settings';

export const SelfviewControls: React.FC<{
    streamStatus: UserMediaStatus;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    permissionInfoType: BlockedBrowserPermissionsInfoType;
}> = ({streamStatus, setShowHelpVideo, permissionInfoType}) => {
    const isMobileDevice = useMobileDevice();
    const audioMuted = useAudioMuteState();
    const videoMuted = useVideoMuteState();
    const toggleAudioMutedState = useToggleAudioMuted();
    const toggleVideoMutedState = useToggleVideoMuted();
    const isAudioInputUnavailable = useAudioInputUnavailable();
    const isVideoInputUnavailable = useVideoInputUnavailable();
    const requested = useHaveRequestedMedia();
    const media = useUserMediaContext();

    const inputStatusInfo = useInputDeviceStatusInfo();
    const track = useSourceAudioTrack();
    const analyzer = useAnalyzer(track);
    const shouldShowVideoStatus =
        streamStatus && isInitialPermissionsNotGranted(streamStatus);

    const toggleAudioAndPersist = () => {
        toggleAudioMutedState(true);
    };

    const toggleVideoAndPersist = () => {
        toggleVideoMutedState(true);
    };

    const {
        videoInputError,
        audioInputError,
        setVideoInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();

    useDeviceErrorMessage({
        setAudioInputError,
        setVideoInputError,
        streamStatus,
        requested,
    });

    return (
        <PreflightMediaControls
            isMobileDevice={isMobileDevice}
            shouldCenter
            inputStatusInfo={inputStatusInfo}
            isAudioInputMuted={audioMuted ?? true}
            isAudioInputUnavailable={isAudioInputUnavailable}
            isVideoInputMuted={videoMuted ?? true}
            isVideoInputUnavailable={isVideoInputUnavailable}
            requestMediaPermissions={() =>
                media.getUserMedia({audio: true, video: true})
            }
            streamStatus={streamStatus}
            toggleAudioInput={toggleAudioAndPersist}
            toggleVideoInput={toggleVideoAndPersist}
            shouldShowVideoStatus={shouldShowVideoStatus}
            audioInputControl={
                analyzer && (
                    <AudioMeter
                        analyzer={analyzer}
                        isAudioInputMuted={audioMuted ?? true}
                        streamStatus={streamStatus}
                        onClick={toggleAudioAndPersist}
                    />
                )
            }
            deviceError={videoInputError || audioInputError}
            setShowHelpVideo={setShowHelpVideo}
            permissionInfoType={permissionInfoType}
        />
    );
};
